<template>
	<Portal :to="teleportTo" :disabled="!teleportTo">
		<div class="applepay">
			<button v-if="cta" @click="$emit('submit')" class="btn applepay__custom-button" :class="classList">
				{{ cta }}
				<ApplePayIcon class="applepay__icon" />
			</button>
			<div
				id="applepay-button"
				class="applepay__native-button"
				:class="{ disabled: classList.disabled, transparent: cta }"
				ref="observeDiv"
			></div>
		</div>
	</Portal>
</template>

<script>
import { Portal } from 'portal-vue'
import ApplePayIcon from '@/components/icons/ApplePayIcon.vue'

export default {
	name: 'ApplePayButton',

	components: { Portal, ApplePayIcon },

	props: {
		teleportTo: {
			type: String,
			default: null,
		},

		classList: {
			type: Object,
			default: () => ({}),
		},

		cta: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			observer: null,
		}
	},

	methods: {
		observe() {
			this.observer = new MutationObserver((mutationsList, observer) => {
				if (this.$refs.observeDiv.querySelector('.primer-apple-pay-button')) {
					this.$emit('ready')
					observer.disconnect()
				}
			})

			this.observer.observe(this.$refs.observeDiv, { childList: true })
		},

		disconnect() {
			if (this.observer) {
				this.observer.disconnect()
			}
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.observe()
		})
	},

	beforeDestroy() {
		this.disconnect()
	},
}
</script>

<style lang="scss" scoped>
.applepay {
	margin-top: 2rem;
	position: relative;

	&__native-button {
		&.disabled {
			opacity: 0.5;
			pointer-events: none;

			> :deep(button) {
				pointer-events: none !important;
			}
		}

		&.transparent {
			position: absolute;
			top: -20px;
			left: 0;
			width: 100%;

			:deep(.primer-apple-pay-button) {
				height: 120px !important;
				width: calc(100% + 70px) !important;
				margin-left: -35px !important;
				border-radius: 0.45rem;
				opacity: 0.001;
			}
		}
	}

	&__custom-button {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		gap: 6px;
		background-color: var(--apple-pay-bg-color);
		color: var(--apple-pay-text-color);

		&.disabled {
			color: var(--apple-pay-text-color);
		}
	}
}

:deep(.primer-apple-pay-button) {
	height: 54px !important;
	border-radius: 0.45rem;
}
</style>
