export default {
	id: 'SIMPLE_CANCEL_OFFER_1M_1',
	providers: {
		paltaMap: {
			USD: 'simple_premium_1m_1_usd',
			EUR: 'simple_premium_1m_1_eur',
			GBP: 'simple_premium_1m_1_gbp',
			CAD: 'simple_premium_1m_1_cad',
			AUD: 'simple_premium_1m_1_aud',
			DKK: 'simple_premium_1m_10_dkk',
			SEK: 'simple_premium_1m_10_sek',
			NOK: 'simple_premium_1m_10_nok',
			BRL: 'simple_premium_1m_5_brl',
			MXN: 'simple_premium_1m_15_mxn',
			TRY: 'simple_premium_1m_29_try',
		},
	},
	trial: {
		days: 0,
		priceMap: {
			USD: 0,
			EUR: 0,
			GBP: 0,
			CAD: 0,
			AUD: 0,
			DKK: 0,
			SEK: 0,
			NOK: 0,
			BRL: 0,
			MXN: 0,
			TRY: 0,
		},
		originalPriceMap: {},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 1,
			EUR: 1,
			GBP: 1,
			CAD: 1,
			AUD: 1,
			DKK: 10,
			SEK: 10,
			NOK: 10,
			BRL: 5,
			MXN: 15,
			TRY: 29,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.30_days.period_text'), // '1-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
