import config from '@/config'

export default {
	id: 'SIMPLE_PAYWALL_1M_15_00_7D_1_00',
	providers: {
		stripe: config('stripe_cheap_and_short'),
		braintree: 'pro_short_cheap',
		paltaMap: {
			USD: 'simple_premium_trial_comm_to_3m_7d_1_usd',
		},
	},
	trial: {
		days: 7,
		priceMap: {
			USD: 1,
		},
		originalPriceMap: {},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 15.0,
		},
	},
	options: ($t) => ({
		commitment: true,
		commitmentPeriodMonths: 3,
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.special_upsale.upgrade_durations.3'), // 3-month
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
