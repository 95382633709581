export default {
	id: 'SIMPLE_UPGRADE_COMM_9M_90',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_9m_90_usd',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 90,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return `You received a discounted rate of ${trialPrice}/mo for your first 6 months. After that, your subscription is set to renew at the standard ${price}/mo.`
		},
		upgradeOfferText: ({ price }) => {
			return `Upgrade to our 9-month plan now to keep your ${price}/mo rate and continue saving!`
		},
		barChartTooltip: 'After 6 months',
		barChartLeftLabel: 'Current monthly rate',
		barChartRightLabel: 'Offer monthly rate',
		upgradeRate: '/mo',
		currentPriceLabel: 'Current monthly price after 6 months',
		offerPriceLabel: $t('activity_upgrade.6mo.offer_price_label'),
	}),
}
