import config from '@/config'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/i18n'
import Vuelidate from 'vuelidate'
import VueScrollTo from 'vue-scrollto'
import VueCookies from 'vue-cookies'
import { InlineSvgPlugin } from 'vue-inline-svg'
import Sentry from '@/modules/Sentry'

import { ENV_DEV_OR_STAGING, VUE_APP_VERSION } from '@/helpers/environment'
import { setDeviceId } from '@/mixins/Experiments'
import Braze from '@/plugins/Braze'
import Amplitude from '@/plugins/Amplitude'
import PriceFormat from '@/plugins/PriceFormat'
import Hotjar from './plugins/Hotjar'

import ABFeatureFlagMixin from '@/mixins/ABFeatureFlagMixin'
import FunnelTypeMixin from '@/mixins/FunnelTypeMixin'

// eslint-disable-next-line no-unused-vars
import anime from 'animejs' // just to make sure it's included in the chunk-vendors bundle

Vue.config.productionTip = false
Vue.config.devtools = ENV_DEV_OR_STAGING

setDeviceId()

Vue.use(PriceFormat, { i18n })
Vue.use(Hotjar, {
	siteId: config('HotjarSiteId'),
	hotjarVersion: config('HotjarVersion'),
	sessionStartEvent: config('HotjarStartSessionEvent'),
	debug: ENV_DEV_OR_STAGING,
})

Vue.use(Braze, {
	apiKey: config('BrazeApiKey'),
	baseUrl: config('BrazeSdkEndpoint'),
	store,
})

Vue.use(Vuelidate)
Vue.use(VueScrollTo)
Vue.use(VueCookies)
Vue.use(InlineSvgPlugin)

window.dataLayer?.push({
	event: 'OneTrustInit',
	onetrustApiKey: config('OnetrustApiKey'),
})

Vue.config.errorHandler = function (error, vm, info) {
	Sentry.withScope((scope) => {
		scope.setExtras({ info })
		scope.setTags({
			vueError: true,
			vueErrorInfo: info,
		})
		scope.captureException(error)
	})

	setTimeout(() => {
		vm?.$analytic?.logEvent('Vue error', {
			message: error.message,
			info,
			location: window.location.href,
		})
	}, 0)
}

Vue.use(Amplitude, {
	options: {
		apiKey: config('AmplitudePaltaWeb'),
		browserOptions: {
			serverUrl: 'https://telemetry.dataplatform.simple.life/v2/amplitude',
			appVersion: VUE_APP_VERSION,
			deviceId: window.deviceId,
			disableCookies: true,
		},

		persistedProperties: {
			onboarding_version: VUE_APP_VERSION,
		},

		/**
		 * The error callback receives server responses with statuses < 200 and >= 300.
		 * @param {object} error - Original amplitude response object
		 * @param {number} error.code - The HTTP response status code of the request.
		 * @param {string} error.message - The response message
		 * @param {object} error.event - The final event object sent to Amplitude
		 */
		onError(error) {
			if (error.code === 0) return
			Sentry.withScope((scope) => {
				scope.setExtras({
					event: error.event,
				})
				scope.setTags({
					analyticErrorCode: error.code,
					analyticErrorMessage: error.message,
				})
				scope.captureMessage(error.message)
			})
		},
		logger: ENV_DEV_OR_STAGING,
	},
	router,
	store,
})

Vue.mixin(ABFeatureFlagMixin)
Vue.mixin(FunnelTypeMixin)

// eslint-disable-next-line vue/require-name-property
new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app')
