import config from '@/config'

export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_1M_17_99',
	providers: {
		stripe: config('stripe_cheap_and_short'),
		braintree: 'pro_short_cheap',
		paltaMap: {
			USD: 'simple_premium_1m_29_99_1m_17_99_usd',
			EUR: 'simple_premium_1m_29_99_1m_17_99_eur',
			GBP: 'simple_premium_1m_29_99_1m_17_99_gbp',
			AUD: 'simple_premium_1m_49_99_1m_26_99_aud',
			CAD: 'simple_premium_1m_49_99_1m_26_99_cad',
			DKK: 'simple_premium_1m_259_1m_129_dkk',
			SEK: 'simple_premium_1m_399_1m_199_sek',
			NOK: 'simple_premium_1m_399_1m_199_nok',
			BRL: 'simple_premium_1m_199_99_1m_99_99_brl',
			MXN: 'simple_premium_1m_499_1m_299_mxn',
			TRY: 'simple_premium_1m_999_1m_599_try',
		},
	},
	trial: {
		days: 30,
		priceMap: {
			USD: 17.99,
			EUR: 17.99,
			GBP: 17.99,
			AUD: 26.99,
			CAD: 26.99,
			DKK: 129,
			SEK: 199,
			NOK: 199,
			BRL: 99.99,
			MXN: 299,
			TRY: 599,
		},
		originalPriceMap: {
			USD: 29.99,
			EUR: 29.99,
			GBP: 29.99,
			AUD: 49.99,
			CAD: 49.99,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.99,
			MXN: 499,
			TRY: 999,
		},
		pricePerDayMap: {
			USD: 0.59,
			GBP: 0.59,
			AUD: 0.89,
			CAD: 0.89,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
			EUR: 29.99,
			GBP: 29.99,
			AUD: 49.99,
			CAD: 49.99,
			DKK: 259,
			SEK: 399,
			NOK: 399,
			BRL: 199.99,
			MXN: 499,
			TRY: 999,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.30_days.period_text'), // '1-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
