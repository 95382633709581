export default {
	id: 'SIMPLE_UPGRADE_9M_108',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_9m_108_usd',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 108,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return $t('activity_upgrade.9mo.subtitle_1', { trialPrice, price })
		},
		upgradeOfferText: ({ price }) => {
			return $t('activity_upgrade.9mo.subtitle_2', { price })
		},
		barChartTooltip: $t('activity_upgrade.9mo.bar_chart_tooltip'),
		barChartLeftLabel: 'Current monthly rate',
		barChartRightLabel: 'Offer monthly rate',
		upgradeRate: $t('activity_upgrade.9mo.upgrade_rate'),
		currentPriceLabel: 'Current monthly price after 6 months',
		offerPriceLabel: 'Monthly price with offer',
	}),
}
