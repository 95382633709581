import Vue from 'vue'
import VueRouter, { isNavigationFailure } from 'vue-router'
import Sentry from '@/modules/Sentry'

import store from '@/store'
import { loadLanguageAsync } from '@/i18n'
import LandingSwap from '@/components/LandingSwap'
import ScreenLayout from '@/components/layouts/ScreenLayout'
import { ENV_DEV_OR_STAGING } from '@/helpers/environment'

import { funnelRoutes } from '@/funnels/funnelRoutes'
import accountRoutes from '@/modules/Account/accountRoutes'
import subscriptionRoutes from '@/router/account/subscriptionRoutes'
import paywallRoutes from '@/modules/Paywall/paywallRoutes'
import manageSubscriptionRoutes from '@/router/account/manageSubscriptionRoutes'
import redirects from '@/router/redirects'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: ScreenLayout,
		children: [
			{
				path: '',
				name: 'landing',
				component: LandingSwap,
				meta: {
					isLanding: true,
					hideHeader: true,
				},
			},

			{
				path: 'account',
				alias: '/survey/account',
				component: ScreenLayout,
				props: {
					hideHeader: true,
				},
				children: [
					{
						path: '',
						name: 'account',
						component: () => import(/* webpackChunkName: "account" */ '@/views/account/Account.vue'),
						meta: {
							requiresAuth: true,
							requiresSubscription: true,
							requiresUser: true,
						},
					},
					...accountRoutes,
					...manageSubscriptionRoutes,
					...subscriptionRoutes,
				],
			},

			{
				path: 'faq',
				name: 'faq',
				alias: '/survey/faq',
				component: () => import(/* webpackChunkName: "srv" */ '@/views/Faq.vue'),
				meta: {
					hideHeader: true,
				},
			},

			{
				path: 'contact-us',
				name: 'contact-us',
				component: () => import(/* webpackChunkName: "srv" */ '@/views/ContactUs.vue'),
				meta: {
					hideHeader: true,
				},
			},

			{
				path: 'result_complete',
				name: 'result_complete',
				component: () => import(/* webpackChunkName: "result" */ '@/views/ResultComplete.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
				},
			},

			{
				path: 'result',
				name: 'result',
				component: () => import(/* webpackChunkName: "result" */ '@/views/pre-paywall/Result.vue'),
			},

			{
				path: 'setting-up-your-account',
				name: 'setting-up-your-account',
				component: () => import(/* webpackChunkName: "result" */ '@/views/pre-paywall/SettingUpYourAccount.vue'),
			},

			{
				path: 'name-form',
				name: 'name-form',
				component: () => import(/* webpackChunkName: "result" */ '@/views/NameForm.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'login',
				name: 'create_account',
				component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
				meta: {
					requiresUser: true,
					gtm: 'login',
				},
			},

			{
				path: 'signin',
				name: 'signin',
				component: () => import(/* webpackChunkName: "signin" */ '@/views/SignIn.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'AccountSignInScreen',
						},
					},
				},
				props: {
					mode: 'default',
				},
			},

			{
				path: 'signin-auth0',
				name: 'signin-auth0',
				component: () => import(/* webpackChunkName: "signin" */ '@/views/SignIn.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'AccountSignInScreen',
						},
					},
				},
				props: {
					mode: 'auth0',
				},
			},

			{
				path: 'signin-email-code-handler',
				name: 'signin-email-code-handler',
				component: () => import(/* webpackChunkName: "signin-email-handler" */ '@/views/SignInEmailHandler.vue'),
				meta: {
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'SignEmailCodeHandlerScreen',
						},
					},
				},
			},

			{
				path: 'signin-confirmation-email-handler',
				name: 'signin-confirmation-email-handler',
				component: () =>
					import(
						/* webpackChunkName: "signin-confirmation-email-handler" */ '@/views/SignInConfirmationEmailHandler.vue'
					),
				meta: {
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'SignEmailCodeHandlerScreen',
						},
					},
				},
			},

			{
				path: 'login_by_code',
				name: 'login_by_code',
				alias: '/survey/login_by_code',
				component: () => import(/* webpackChunkName: "login_by_code" */ '@/views/LoginByCode.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'download',
				name: 'download',
				alias: '/survey/download',
				component: () => import(/* webpackChunkName: "update-profile" */ '@/views/download/Index'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'DownloadAppScreen',
						},
					},
				},
			},

			{
				path: 'cancel_subscription',
				name: 'cancel_subscription',
				component: () => import(/* webpackChunkName: "account" */ '@/views/account/CancelSubscription.vue'),
				meta: {
					requiresAuth: true,
					requiresSubscription: true,
					requiresUser: true,
				},
			},

			{
				path: 'confirm_email',
				name: 'confirm_email',
				component: () => import(/* webpackChunkName: "account" */ '@/views/account/ConfirmEmail.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
				},
			},

			{
				path: 'change_email',
				name: 'change_email',
				component: () => import(/* webpackChunkName: "account" */ '@/views/account/ChangeEmail.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'subscription-activated',
				name: 'subscription-activated',
				component: () => import(/* webpackChunkName: "upsell" */ '@/views/SubscriptionActivated.vue'),
				meta: {
					requiresUser: true,
					requiresSubscription: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_CONGRATS_ON_SUBSCRIPTION_SCREEN',
						},
					},
				},
			},

			{
				path: 'special-upsale',
				name: 'special-upsale',
				component: () => import(/* webpackChunkName: "upsell" */ '@/views/upsell/SpecialUpsale.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upgrade',
				name: 'upgrade',
				component: () => import(/* webpackChunkName: "upsell" */ '@/views/upsell/Upgrade.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-essential',
				name: 'upsell-essential',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'essentialGuides' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'optimized-upsell',
				name: 'optimized-upsell',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'optimizedUpsell' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-activity',
				name: 'upsell-activity',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'activity' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-activity-face-massage',
				name: 'upsell-activity-face-massage',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'faceMassage' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-activity-sex-health',
				name: 'upsell-activity-sex-health',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'sexHealth' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'update-profile',
				name: 'update-profile',
				component: () => import(/* webpackChunkName: "update-profile" */ '@/views/UpdateProfile.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'UpdateProfileScreen',
						},
					},
				},
			},

			{
				path: 'redirect',
				name: 'redirect',
				component: () => import(/* webpackChunkName: "redirect" */ '@/views/Redirect.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'personalized-plan-activity',
				name: 'personalized-plan-activity',
				component: () =>
					import(/* webpackChunkName: "pre-paywall" */ '@/views/pre-paywall/PersonalizedPlanActivity.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_PLAN_INFO_SCREEN',
						},
					},
				},
			},

			{
				path: 'personalized-plan-walking',
				name: 'personalized-plan-walking',
				component: () =>
					import(/* webpackChunkName: "pre-paywall" */ '@/views/pre-paywall/PersonalizedPlanWalking.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_PLAN_INFO_SCREEN',
						},
					},
				},
			},

			{
				path: 'personalized-plan-yoga',
				name: 'personalized-plan-yoga',
				component: () => import(/* webpackChunkName: "pre-paywall" */ '@/views/pre-paywall/PersonalizedPlanYoga.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_PLAN_INFO_SCREEN',
						},
					},
				},
			},

			{
				path: 'your-plan-ready',
				name: 'your-plan-ready',
				component: () =>
					import(/* webpackChunkName: "your-plan-ready" */ '@/views/pre-paywall/YourPlanReadyAnimation.vue'),
				meta: {
					hideHeader: true,
				},
			},

			{
				path: 'first-results',
				name: 'first-results',
				component: () => import(/* webpackChunkName: "pre-paywall" */ '@/views/pre-paywall/FirstResults.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_FIRST_VISIBLE_RESULTS_SCREEN',
						},
					},
				},
			},

			{
				path: 'free-guides',
				name: 'free-guides',
				component: () => import(/* webpackChunkName: "pre-paywall" */ '@/views/pre-paywall/FreeGuides.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_GET_FREE_GUIDES_SCREEN',
						},
					},
				},
			},

			{
				path: 'personal-plan',
				name: 'personal-plan',
				component: () => import(/* webpackChunkName: "your-health-coach" */ '@/views/personal-plan/PersonalPlan.vue'),
				meta: {
					requiresUser: true,
					requiresSubscription: true,
				},
			},
		],
	},

	...funnelRoutes(),
	...paywallRoutes,

	{
		path: '/a-input',
		name: 'age-input',
		component: () => import(/* webpackChunkName: "age-input" */ '@/views/AgeInputScreen.vue'),
	},

	{
		path: '/a-input-activity',
		name: 'a-input-activity',
		component: () => import(/* webpackChunkName: "activity-landing" */ '@/views/landings/LandingActivity.vue'),
	},

	{
		path: '/assessment/report',
		name: 'assessment-report',
		component: () => import(/* webpackChunkName: "assessment-survey" */ '@/views/assessment/AssessmentReport.vue'),
		meta: {
			requiresUser: true,
		},
	},

	{
		path: '/assessment/result',
		name: 'assessment-result',

		component: () => import(/* webpackChunkName: "assessment-survey" */ '@/views/assessment/AssessmentResult.vue'),
	},

	{
		path: '/assessment/f/:page*',
		name: 'assessmen-survey',
		redirect: { name: 'female-assessment-survey' },
	},

	{
		path: '/assessment/f/:page*',
		name: 'female-assessment-survey',
		component: () => import(/* webpackChunkName: "assessment-survey" */ '@/views/assessment/AssessmentSurvey.vue'),
		props: { instance: 'femaleAssessment' },
	},

	...redirects,

	{
		path: '*',
		name: '404',
		component: () => import(/* webpackChunkName: "not-found" */ '@/views/EmptyPage.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: store.getters['location/basePath'],
	routes,
	scrollBehavior() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({ x: 0, y: 0 })
			}, 0)
		})
	},
})

router.beforeEach(async (to, from, next) => {
	const blockingRequests = [
		store.dispatch('coreFeatures/hydrateCoreFeatures'),
		store.dispatch('fetchGeo', {
			country: to.query.country,
		}),
		loadLanguageAsync(),
	]

	// При логине через auth0 происходят редиректы туда-сюда, и юзера мы хотим получать только после того как отработала логика на странице логина
	// иначе появляется состояние гонки(юзер запрашивается со старым токеном вот здесь, а нам нужно сначала выполнить логику со страницы с установкой новых токенов)
	if (to.name !== 'signin-auth0' && !store.state.user) {
		const fetchUserRequest = store.dispatch('fetchUserFromAws')

		if (to.meta.requiresUser) {
			blockingRequests.push(fetchUserRequest)
		}
	}

	await Promise.all(blockingRequests)
	store.commit('status/setResourceReadiness', 'network')

	let isAuthenticated = store.getters.isAuthenticated
	let isOnboarded = store.getters.isOnboarded

	if (to.meta.requiresAuth && !isAuthenticated) {
		next({
			name: 'create_account',
			query: {
				redirect: to.name,
			},
		})
		return
	}

	if (to.meta.requiresOnboarding && !isOnboarded) {
		const landingPath = store.getters['location/getLandingPathByPaywallRoute'](to.name)

		Vue.prototype.$analytic?.logEvent('RedirectToOnboarding', {
			user: store.state.user,
			main_user_id: store.state.main_user_id,
			from_path: from.path,
			from_path_name: from.name,
			to_path: to.path,
			to_path_name: to.name,
		})

		await store.dispatch('location/storeInitialPath', {
			force: true,
			path: landingPath,
		})

		next({
			path: landingPath,
			query: {
				track: true,
			},
			replace: true,
		})
		return
	}

	if (to.meta.requiresSubscription && !store.state.subscription && isAuthenticated) {
		next({
			name: 'plan-main',
			query: {
				plan: 'redirect', // FIXME: temporary solution for debug purposes
			},
			replace: true,
		})
		return
	}

	next()
})

const originalPushMethod = VueRouter.prototype.push
const originalReplaceMethod = VueRouter.prototype.replace

function navigationChangedHandler(originalMethod, location, onComplete, onAbort) {
	if (onComplete || onAbort) {
		return originalMethod.call(this, location, onComplete, onAbort)
	}

	return originalMethod.call(this, location).catch((err) => {
		// Ignore the NavigationDuplicated error on the console
		if (err.name === 'NavigationDuplicated') {
			return
		}

		if (ENV_DEV_OR_STAGING) {
			console.warn(err)
			Sentry.captureException(err)
		}

		if (isNavigationFailure(err)) {
			return err
		}

		return Promise.reject(err)
	})
}

VueRouter.prototype.push = function (location, onComplete, onAbort) {
	return navigationChangedHandler.call(this, originalPushMethod, location, onComplete, onAbort)
}

VueRouter.prototype.replace = function replace(location, onComplete, onAbort) {
	return navigationChangedHandler.call(this, originalReplaceMethod, location, onComplete, onAbort)
}

router.onError((error) => {
	if (error.name === 'ChunkLoadError') {
		Sentry.withScope((scope) => {
			scope.setTags({
				chunkLoadErrorRequest: error.request,
			})
			scope.captureException(error)
		})
	}
})

export default router
