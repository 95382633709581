<template>
	<transition :name="animation">
		<div @click="$emit('backdrop-click')" class="modal show" tabindex="-1" role="dialog" v-if="show">
			<div class="modal-dialog" :class="alignmentClass" role="document">
				<div class="modal-content">
					<button type="button" v-if="closeButtonVisibility" class="modal-close" @click="onClickCloseBtn">
						<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill="currentColor"
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M9.13318 8.43201C8.55717 9.00801 8.55717 9.94191 9.13318 10.5179L14.6153 16L9.13318 21.4821C8.55717 22.0581 8.55717 22.992 9.13318 23.568C9.70919 24.144 10.6431 24.144 11.2191 23.568L16.7012 18.0859L22.1833 23.568C22.7593 24.144 23.6932 24.144 24.2692 23.568C24.8452 22.992 24.8452 22.0581 24.2692 21.4821L18.7871 16L24.2692 10.5179C24.8452 9.94191 24.8452 9.00801 24.2692 8.43201C23.6932 7.856 22.7593 7.856 22.1833 8.43201L16.7012 13.9141L11.2191 8.43201C10.6431 7.856 9.70919 7.856 9.13318 8.43201Z"
							/>
						</svg>
					</button>
					<div class="modal-body" :class="{ 'has-close': closeButtonVisibility }">
						<slot name="body"></slot>
					</div>
				</div>
			</div>
			<div @click="$emit('click:backdrop')" class="modal-backdrop fade show"></div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'Modal',

	props: {
		show: {
			type: Boolean,
			default: false,
		},

		animation: {
			type: String,
			default: 'fade',
		},

		closeButtonVisibility: {
			type: Boolean,
			default: false,
		},

		modalName: {
			type: String,
			default: null,
		},

		path: {
			type: Array,
			default: () => [],
		},

		alignment: {
			type: String,
			default: 'center',
			validator: (value) => ['center', 'bottom', 'bottom-sheet', 'full-screen'].includes(value),
		},
	},

	data() {
		return {
			closeButtonClicked: false,
		}
	},

	computed: {
		alignmentClass() {
			switch (this.alignment) {
				case 'bottom-sheet':
					return 'bottom-sheet'
				case 'full-screen':
					return 'modal-fullscreen'
				case 'center':
					return 'modal-dialog-centered'
				case 'bottom':
					return 'modal-dialog-bottom'
				default:
					return 'modal-dialog-bottom'
			}
		},
	},

	watch: {
		show(value) {
			// Если загружаем экран, и модалка по-умолчанию открыта, то стайл на боди не проставляется,
			// а если делаем immediate: true в то время, когда сразу две модалки на странице существуют (необязательно открытые)
			// то получаем множественный вызов вотчера и результат зависящий от того как они в шаблоне расположены
			if (value) {
				this.logEvent('PopupView')
				this.$emit('open')
				document.body.style.overflow = 'hidden'
			} else {
				// So 'done_btn' won't be logged if modal was closed by clicking on close button
				if (!this.closeButtonClicked) {
					this.logEvent('PopupClose', {
						how: 'done_btn',
					})
				}
				document.body.style.overflow = 'auto'
				this.closeButtonClicked = false
			}
		},
	},

	methods: {
		onClickCloseBtn() {
			this.closeButtonClicked = true
			this.logEvent('PopupClose', {
				how: 'close_btn',
			})
			this.$emit('close')
		},

		logEvent(event, props = {}) {
			if (this.modalName) {
				this.$analytic.logEvent(event, {
					title: this.modalName,
					path: this.path?.length ? this.path : undefined,
					...props,
				})
			}
		},
	},

	beforeDestroy() {
		document.body.style.overflow = 'auto'
	},
}
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.modal-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	color: currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	box-shadow: none;
	z-index: 9;
	background-color: transparent;
	padding: 0;
	font-size: 1.2rem;
	transition: opacity 0.3s;
	outline: none;
	width: 1.6rem;
	opacity: 0.5;

	svg {
		display: block;
		width: 100%;
	}

	&:hover {
		opacity: 0.7;
	}
}

.modal-body {
	&.has-close {
		padding-top: 30px !important;
	}
}

.modal-title {
	text-align: center;
	font-weight: bold;
	font-size: 1.3rem;
	margin-bottom: 0.6rem;

	&-md {
		font-size: 1.5rem;
		font-weight: 900;
		line-height: 1.2;
		margin-bottom: 0.8rem;
	}

	&-md {
		font-size: 1.5rem;
	}

	&-lg {
		font-size: 1.7rem;
		font-weight: 900;
	}

	&-extra {
		font-size: 2rem;
		font-weight: 900;
		padding: 0;
		line-height: 1.1;
	}
}

.modal-subtitle {
	text-align: center;
	margin-top: 0.6rem;
	line-height: 1.2;
}

.modal-form {
	margin: 2rem 0;

	.btn {
		width: 100%;
	}
}

.modal-note {
	text-align: center;
	color: #666260;
	font-size: 0.94rem;
}

.btn {
	margin-top: 1rem;
	width: 100%;
}

.modal-question {
	.modal-dialog {
		align-items: flex-end;
		display: flex;
		height: 100vh;
		height: 100dvh;
		padding: 0;

		@media (min-width: 768px) {
			align-items: center;
		}
	}

	.modal-close {
		display: none;
	}

	.modal-content {
		border: none;
		border-radius: 10px 10px 0 0;
		max-height: 100%;
		overflow-y: auto;

		@media (min-width: 768px) {
			border-radius: 10px;
		}
	}

	.modal-title {
		margin-bottom: 1.4rem !important;
		padding-left: 2rem;
		padding-right: 2rem;
		line-height: 1.2;
	}

	.modal-body {
		padding-bottom: 30px;
	}
}

.modal-rules {
	.modal-body {
		max-height: 90vh;
		overflow-y: auto;
		padding: 0;
	}

	.modal-dialog {
		@media (min-width: 768px) {
			max-width: 700px;
		}
	}

	.modal-dialog-centered {
		min-height: 100%;
	}

	.modal-close {
		opacity: 0.5;
	}

	.modal-scroll {
		padding: 25px 20px;
		font-size: 0.92rem;
		word-wrap: break-word;

		@media (min-width: 768px) {
			padding: 30px;
			font-size: 0.82rem;
		}

		ul {
			padding-left: 20px;

			li {
				margin-bottom: 0.4rem;
				font-size: 0.92rem;

				@media (min-width: 768px) {
					font-size: 0.82rem;
				}
			}
		}

		h5 {
			margin-top: 2rem;
			font-weight: 500;
		}
	}
}

.modal-trial {
	height: 100%;
	overflow-y: auto;
}

.pay-modal {
	overflow-y: auto;

	.modal-dialog {
		padding: 15px;
	}
}

.modal-downgrade {
	text-align: center;

	p {
		font-size: 1.16rem;
	}

	.modal-discount {
		width: 120px;
	}
}

.slide-bottom {
	.modal-dialog {
		padding-left: 0;
		padding-right: 0;
		padding-top: 2.5rem;
		height: 100%;

		@media (min-width: 768px) {
			padding-bottom: 6rem;
		}
	}

	.modal-content {
		border: none;
		border-radius: 1rem 1rem 0 0;
		overflow-y: scroll;
		align-items: flex-start;
		height: 100%;
		position: relative;

		@media (min-width: 768px) {
			border-radius: 1rem;
		}
	}

	.modal-title {
		margin-bottom: 0;
	}
}

.modal-dialog {
	&.bottom-sheet {
		padding: 0;
		max-width: 100%;
		margin: 0;
		height: 100%;
		align-items: flex-end;

		.modal-content {
			border: none;
			margin-top: 60px;
			height: calc(100dvh - 60px);
			overflow-y: auto;
			border-radius: 0;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			padding-top: 15px;
			position: relative;

			.modal-body {
				padding: 0;
			}
		}

		.modal-close {
			opacity: 1;
			top: 14px;
			right: 14px;
		}
	}

	&.modal-fullscreen {
		padding: 0;
		max-width: 100%;
		margin: 0;

		.modal-content {
			border: none;
			height: 100vh;
			height: 100dvh;
			overflow-y: auto;
			border-radius: 0;

			.modal-body {
				padding: 0;
			}
		}
	}
}

.modal-commitment {
	.modal-title {
		line-height: 1.34;
		text-align: left;
	}

	.modal-content {
		border-radius: 1rem;
		background-color: var(--result-modal-bg-color);
	}

	.modal-image {
		margin: 2rem 0;
		text-align: center;

		& + .modal-title {
			margin-bottom: 2rem !important;
		}

		& img {
			width: 180px;
		}
	}

	.buttons {
		margin-top: 1rem;

		.btn-secondary {
			background-color: var(--result-modal-button-bg-color);
			border-color: var(--result-modal-button-bg-color);
			padding: 1.2rem 1rem;
			text-align: left;
			border-radius: 0.8rem;
			border-width: 2px;
			font-weight: 600;
			color: var(--result-modal-button-text-color);
		}

		.btn-secondary,
		.btn-primary-light {
			font-weight: bold;
			margin-top: 0;
			margin-bottom: 0.6rem;

			&:focus,
			&:active {
				background-color: var(--result-modal-button-bg-color-active) !important;
				border-color: #98a4ff !important;
				color: var(--text-color-primary) !important;
				font-weight: 600 !important;
				box-shadow: none !important;
			}
		}
	}
}

.extra-large-close-btn {
	.modal-close {
		width: 3rem;
		height: 3rem;
		opacity: 1;

		svg {
			height: 100%;
		}
	}
}
</style>
