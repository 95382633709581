export default {
	id: 'SIMPLE_UPGRADE_COMM_6M_72',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_6m_72_usd',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 6,
		priceMap: {
			USD: 72,
		},
	},
	options: ($t) => ({
		currentSubscriptionRateText: ({ trialPrice, price }) => {
			return `You paid ${trialPrice} for the first month of your subscription. After that, it’s set to renew at the standard ${price}/mo.`
		},
		upgradeOfferText: ({ price }) => {
			return `Upgrade to our 6-month plan now to pay just ${price}/mo — forever — and enjoy savings!`
		},
		barChartTooltip: 'After 1 month',
		barChartLeftLabel: $t('activity_upgrade.6mo.bar_chart_left_label'),
		barChartRightLabel: $t('activity_upgrade.6mo.bar_chart_right_label'),
		upgradeRate: $t('activity_upgrade.6mo.upgrade_rate'),
		currentPriceLabel: 'Current monthly price after 1 month',
		offerPriceLabel: $t('activity_upgrade.6mo.offer_price_label'),
	}),
}
