<template>
	<Modal v-show="show" :show="true" @click:backdrop="onClickBackdrop">
		<template #body>
			<div class="renewal-consent">
				<div class="renewal-consent__icon">
					<inline-svg :src="badgePath" />
				</div>
				<div class="renewal-consent__title">Activate your 30-day money-back guarantee</div>
				<div class="renewal-consent__description">
					By consenting to subscription auto-renewal, you activate your 30-day money-back guarantee — giving you
					complete confidence in your plan.
				</div>
				<div class="renewal-consent__actions">
					<button @click="onClickFallback" class="btn btn-success" :class="{ btn_loading: loading }">
						<span class="spinner-border spinner-border-sm"></span>
						<span class="btn__content">Consent and activate</span>
					</button>
					<div v-show="!loading" class="renewal-consent__payment-methods">
						<div v-show="paymentMethod === 'card'" @click="onClickCard" class="concent-modal-card"></div>
						<div v-show="paymentMethod === 'paypal'" class="concent-modal-paypal"></div>
						<div v-show="paymentMethod === 'apple_pay'" class="concent-modal-apple-pay"></div>
					</div>
				</div>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
	name: 'RenewalConsentModal',
	components: { Modal },

	props: {
		show: {
			type: Boolean,
			default: false,
		},

		paymentMethod: {
			type: String,
			default: null,
		},

		loading: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		badgePath() {
			if (this.IS_DARK_THEME) {
				return require('@/assets/paywall/subscription-consent-badge-dark.svg')
			}

			return require('@/assets/paywall/subscription-consent-badge-light.svg')
		},
	},

	watch: {
		show() {
			this.$analytic.logEvent(this.show ? 'PopupView' : 'PopupClose', {
				title: 'ONBOARDING_AUTORENEWAL_CONSENT_POPUP',
			})
		},
	},

	methods: {
		onClickFallback() {
			this.$emit('click:fallback')
		},

		onClickCard() {
			this.$emit('click:card')
		},

		onClickBackdrop() {
			this.$emit('click:backdrop')
		},
	},
}
</script>

<style lang="scss" scoped>
.renewal-consent {
	padding: 4px;
	text-align: center;

	&__icon {
		margin-top: -60px;
	}

	&__title {
		font-size: var(--size-24);
		font-weight: 700;
		line-height: var(--size-28);
		margin-top: 12px;
	}

	&__description {
		margin-top: 12px;
		line-height: var(--size-22);
	}

	&__actions {
		margin-top: var(--size-24);
		position: relative;

		.btn {
			margin: 0;
			font-weight: 600;
			position: relative;

			.spinner-border {
				position: absolute;
				left: 50%;
				top: 50%;
				margin-top: -0.68rem;
				margin-left: -0.68rem;
				opacity: 0;
			}

			&_loading {
				pointer-events: none;
				.spinner-border {
					opacity: 1;
				}
				.btn__content {
					opacity: 0;
				}
			}
		}
	}

	&__payment-methods {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.001;

		div {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
